<template>
  <div class="product-list__item">
    <div class="product-list__item__description">
      <div class="product-list__item__description__icon">
        <font-awesome-icon :icon="iconComputed" class="product-list__item__description__icon" />
      </div>
      <div class="product-list__item__description__content">
        {{description}}
        <b-button
          v-if="hasExtraInfo"
          variant="info"
          size="sm"
        >
          <font-awesome-icon
            icon="info"
            class="fa-fw"
          />
        </b-button>
      </div>
    </div>
    <div
      class="product-list__item__extras"
      v-if="hasBoughtItem"
    >
      <div>
        <div>Ultimo acquisto</div>
        <div>{{ boughtItemDate }}</div>
      </div>
    </div>
    <div :class="costClasses">
      <div>
        <div>{{ costLabel }}</div>
        <div>{{ cost }}</div>
      </div>
    </div>
    <div class="product-list__item__action">
      <b-button
        variant="info"
        @click="dlAction()"
        size="sm"
        class="me-2"
        v-if="hasBoughtItem"
      >
        <font-awesome-icon
          icon="download"
          class="fa-fw me-2 me-md-0 me-xl-2"
        />
        <span class="d-md-none d-xl-inline-block">{{dlActionLabel}}</span>
      </b-button>
      <div v-if="isOperative">
        <b-button
          variant="success"
          @click="buyAction(item)"
          size="sm"
          v-if="!isQuote"
        >
          <font-awesome-icon
            icon="cart-shopping"
            class="fa-fw me-2 me-md-0 me-xl-2"
          />
          <span class="d-md-none d-xl-inline-block">{{buyActionLabel}}</span>
        </b-button>
        <b-button
          variant="info"
          @click="requestQuote(item)"
          size="sm"
          v-if="isQuote"
        >
          <font-awesome-icon
            icon="info"
            class="fa-fw me-2 me-md-0 me-xl-2"
          />
          <span class="d-md-none d-xl-inline-block">Richiedi informazioni</span>
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { formatCurrency, formatDateOnly } from '@/utils/formatterHelper';
import { isNotEmpty, isNotExist } from '@/utils/validators';
import { USER_ANALYSIS_CLASS } from '@/utils/interfaces';
import { getEntityApiBase, openPrivateDocument } from '@/utils/documents';

export default {
  name: 'ProductTableItem',
  components: {},
  props: {
    item: {
      value: String,
      info: String,
      key: String,
    },
    buyAction: Function,
    requestQuote: Function,
    requestType: String,
    singleProfile: Boolean,
    boughtItem: { ...USER_ANALYSIS_CLASS },
  },
  computed: {
    notAvailableMsg() {
      return 'N.D.';
    },
    description() {
      return this.item.value || this.notAvailableMsg;
    },
    costLabel() {
      if (this.isQuote) return 'A partire da';
      return 'Costo';
    },
    cost() {
      return this.decodePricing(this.item);
    },
    hasExtraInfo() {
      return isNotEmpty(this.item.info);
    },
    iconComputed() {
      // insert different icons here
      return 'file-contract';
    },
    hasBoughtItem() {
      // check su item se comprato e singleProfile
      // console.log(
      //   'this.boughtItem',
      //   this.boughtItem,
      // );
      // console.log('this.boughtItem', this.boughtItem);
      return this.singleProfile && isNotEmpty(this.boughtItem);
    },
    boughtItemDate() {
      // return formatDateTime(this.boughtItem.lastModified);
      return formatDateOnly(this.boughtItem.lastModified);
    },
    buyActionLabel() {
      const baseLabel = 'Acquista';
      const altLabel = 'Acquista di nuovo';
      return this.hasBoughtItem ? altLabel : baseLabel;
    },
    dlActionLabel() {
      return 'Scarica';
    },
    isQuote() {
      // console.log('item', this.item, isNotEmpty(this.item?.additionalInfos?.info));
      // checking also if OPERATIVE
      return isNotEmpty(this.item?.additionalInfos?.info);
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    costClasses() {
      const a = 'product-list__item__cost';
      if (!this.hasBoughtItem && !this.isOperative) return `${a} is-last-child`;
      return a;
    },
  },
  mounted() {
    this.loadDocuments();
  },
  methods: {
    decodePricing(item) {
      // console.log('decodePricing', item);
      const pricing = this.$store.getters['wallet/getPricing'](this.requestType, item.key);
      if (pricing) {
        if (isNotEmpty(item.additionalInfos)) {
          if (item.additionalInfos.search?.hasPricing) {
            const { searchCode, searchType } = item.additionalInfos.search;
            const searchPrice = this.$store.getters['wallet/getPricing'](searchType, searchCode);
            if (searchPrice && searchPrice.price.amount > 0) {
              pricing.price.amount += searchPrice.price.amount;
            }
          }
        }
        return formatCurrency(pricing.price.amount, 'it', pricing.price.currency);
      }
      return 'NA';
    },
    dlAction() {
      // qui recupero il download
      const doc = this.$store.getters['documents/getDocumentList']('USERANALYSIS', this.boughtItem.requestName);
      // console.log('doc.length', doc, doc.length);
      openPrivateDocument(doc[doc.length - 1].documentId, getEntityApiBase('USERANALYSIS'));
    },
    loadDocuments() {
      // console.debug('Load documents', this.boughtItem?.requestName, this.item);
      if (isNotExist(this.boughtItem)) return;
      if (this.boughtItem?.status === 'COMPLETED') {
        // console.debug('Load documents: fecth', this.boughtItem.status);
        this.$store.dispatch('documents/loadDocuments', {
          entityClass: 'USERANALYSIS',
          entityId: this.boughtItem.requestName,
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
